exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-afirmatii-js": () => import("./../../../src/pages/afirmatii.js" /* webpackChunkName: "component---src-pages-afirmatii-js" */),
  "component---src-pages-blog-bun-venit-js": () => import("./../../../src/pages/blog/bun-venit.js" /* webpackChunkName: "component---src-pages-blog-bun-venit-js" */),
  "component---src-pages-blog-e-vineri-js": () => import("./../../../src/pages/blog/e-vineri.js" /* webpackChunkName: "component---src-pages-blog-e-vineri-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-ne-gandim-la-tine-js": () => import("./../../../src/pages/blog/ne-gandim-la-tine.js" /* webpackChunkName: "component---src-pages-blog-ne-gandim-la-tine-js" */),
  "component---src-pages-blog-sarbatori-linistite-js": () => import("./../../../src/pages/blog/sarbatori-linistite.js" /* webpackChunkName: "component---src-pages-blog-sarbatori-linistite-js" */),
  "component---src-pages-confirm-subscription-js": () => import("./../../../src/pages/confirm-subscription.js" /* webpackChunkName: "component---src-pages-confirm-subscription-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-delete-account-js": () => import("./../../../src/pages/delete-account.js" /* webpackChunkName: "component---src-pages-delete-account-js" */),
  "component---src-pages-delete-data-js": () => import("./../../../src/pages/delete-data.js" /* webpackChunkName: "component---src-pages-delete-data-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jurnal-js": () => import("./../../../src/pages/jurnal.js" /* webpackChunkName: "component---src-pages-jurnal-js" */),
  "component---src-pages-newsletter-success-js": () => import("./../../../src/pages/newsletter-success.js" /* webpackChunkName: "component---src-pages-newsletter-success-js" */),
  "component---src-pages-payment-confirmed-js": () => import("./../../../src/pages/payment-confirmed.js" /* webpackChunkName: "component---src-pages-payment-confirmed-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-test-android-js": () => import("./../../../src/pages/test-android.js" /* webpackChunkName: "component---src-pages-test-android-js" */),
  "component---src-pages-test-iphone-js": () => import("./../../../src/pages/test-iphone.js" /* webpackChunkName: "component---src-pages-test-iphone-js" */),
  "component---src-pages-whatsapp-js": () => import("./../../../src/pages/whatsapp.js" /* webpackChunkName: "component---src-pages-whatsapp-js" */)
}

