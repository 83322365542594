import React, { createContext, useState, useContext } from "react";

// Create the ThemeContext
const ThemeContext = createContext();

// Custom hook for accessing the context
export const useTheme = () => {
  const context = useContext(ThemeContext);

  // If the context is undefined, return a fallback to prevent build-time errors
  if (!context) {
    return {
      isNightMode: false, // Default value for SSR
      toggleNightMode: () => {}, // No-op function for SSR
    };
  }

  return context;
};

// decide if it's night or day based on the time
const isNight = () => {
  const hours = new Date().getHours();
  return hours < 6 || hours > 18;
};

// ThemeContext provider
export const ThemeProvider = ({ children }) => {
  const [isNightMode, setIsNightMode] = useState(isNight());

  // Toggle night mode
  const toggleNightMode = () => setIsNightMode(!isNightMode);

  return (
    <ThemeContext.Provider value={{ isNightMode, toggleNightMode }}>
      {children}
    </ThemeContext.Provider>
  );
};
